import { PatientVitalsInterface } from '../../shared/model/report.model'

export function abnormalMissingFilter(
  data: Partial<PatientVitalsInterface>[],
  isAbnormalMissingFilter: boolean
) {
  if (!isAbnormalMissingFilter) return data

  const checkIsMissingFilter = (p: Partial<PatientVitalsInterface>): boolean =>
    [
      p.vitals?.bodyTemperature?.isMissing,
      p.vitals?.systolicPressure?.isMissing,
      p.vitals?.diastolicPressure?.isMissing,
      p.vitals?.heartRate?.isMissing,
      p.vitals?.respirationRate?.isMissing,
      p.vitals?.spo2?.isMissing
    ].some(Boolean)

  const checkHasVitalTPFilter = (p: Partial<PatientVitalsInterface>): boolean =>
    [
      p.checkShiftSentEmrInformation &&
        p.checkShiftSentEmrInformation.hasOwnProperty('bp') &&
        !p.vitals?.diastolicPressure?.value,
      p.checkShiftSentEmrInformation &&
        p.checkShiftSentEmrInformation.hasOwnProperty('bp') &&
        !p.vitals?.systolicPressure?.value,
      p.checkShiftSentEmrInformation &&
        p.checkShiftSentEmrInformation.hasOwnProperty('spo2') &&
        !p.vitals?.spo2?.value,
      p.checkShiftSentEmrInformation &&
        p.checkShiftSentEmrInformation.hasOwnProperty('rr') &&
        !p.vitals?.respirationRate?.value,
      p.checkShiftSentEmrInformation &&
        p.checkShiftSentEmrInformation.hasOwnProperty('hr') &&
        !p.vitals?.heartRate?.value,
      p.checkShiftSentEmrInformation &&
        p.checkShiftSentEmrInformation.hasOwnProperty('bt') &&
        !p.vitals?.bodyTemperature?.value
    ].some(Boolean)

  const checkPatientMissOrCriticalVitalFilter = (
    p: Partial<PatientVitalsInterface>
  ): boolean =>
    [
      p.vitals?.bodyTemperature?.isCriticalVital &&
        p.vitals?.bodyTemperature?.isActualValue,
      p.vitals?.systolicPressure?.isCriticalVital &&
        p.vitals?.systolicPressure?.isActualValue,
      p.vitals?.diastolicPressure?.isCriticalVital &&
        p.vitals?.diastolicPressure?.isActualValue,
      p.vitals?.heartRate?.isCriticalVital &&
        p.vitals?.heartRate?.isActualValue,
      p.vitals?.respirationRate?.isCriticalVital &&
        p.vitals?.respirationRate?.isActualValue,
      p.vitals?.spo2?.isCriticalVital && p.vitals?.spo2?.isActualValue
    ].some(Boolean)

  return data.filter(p => {
    return (
      checkPatientMissOrCriticalVitalFilter(p) ||
      checkIsMissingFilter(p) ||
      checkHasVitalTPFilter(p)
    )
  })
}
