import { Injectable } from '@angular/core'
import {
  WebSocketSubject,
  WebSocketSubjectConfig
} from 'rxjs/internal/observable/dom/WebSocketSubject'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private socket$: WebSocketSubject<any>
  private url: string

  constructor() {}

  public connect(): Observable<any> {
    return this.socket$.asObservable()
  }

  public send(message: any): void {
    this.socket$.next(message)
  }

  public close(): void {
    if (this.socket$) {
      this.socket$.complete()
    }
  }

  public setUrl(url: string): void {
    this.url = url
    if (this.socket$) {
      this.close()
    }
    this.createWebSocket()
  }

  private createWebSocket(): void {
    const config: WebSocketSubjectConfig<any> = {
      url: this.url,
      serializer: msg => JSON.stringify(msg),
      deserializer: msg => JSON.parse(msg.data)
    }
    this.socket$ = new WebSocketSubject(config)
  }
}
