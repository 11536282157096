import { InsightDTO } from '../../shared/model/insight.model'
import { AlertSubject } from '../../shared/model/alert'
import { orderBy } from 'lodash-es'

export function extractSpecificInsightBySubject(
  insights: (InsightDTO & {
    insightSubject: AlertSubject
    lastModifiedTime: Date | string
  })[],
  subject: AlertSubject
): InsightDTO | null {
  if (!insights) return null
  const copyInsights = [...insights].filter(
    insight => insight.insightSubject === subject
  )
  if (!copyInsights || !copyInsights.length) return null
  return orderBy(copyInsights, 'lastModifiedTime', 'asc')[0]
}
