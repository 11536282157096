import { Injectable } from '@angular/core'
import { Moment } from 'moment'
import moment from 'moment/moment'

export class Shift {
  shiftDate: Moment
  shiftSequence: number
  shiftName: string
  shiftStart: Moment
  shiftEnd: Moment

  constructor(
    shiftDate: Moment,
    shiftSequence: number,
    shiftStart: Moment,
    shiftEnd: Moment,
    shiftName: string
  ) {
    this.shiftDate = shiftDate
    this.shiftSequence = shiftSequence
    this.shiftName = shiftName
    this.shiftStart = shiftStart
    this.shiftEnd = shiftEnd
  }

  checkShiftTime(timestamp: Moment): boolean {
    return (
      timestamp.isSameOrAfter(this.shiftStart) &&
      timestamp.isBefore(this.shiftEnd)
    )
  }
}

@Injectable({
  providedIn: 'root'
})
export class ShiftService {
  constructor() {}

  generateShiftsForDate(date: Moment): Shift[] {
    const shifts: Shift[] = []
    const shiftDate = moment(date).startOf('day')

    const morningShiftStart = moment(shiftDate).hour(7).minutes(0).seconds(0)
    const morningShiftEnd = moment(shiftDate).hour(15).minutes(0).seconds(0)
    shifts.push(
      new Shift(shiftDate, 0, morningShiftStart, morningShiftEnd, 'Day Shift')
    )

    const dayShiftStart = moment(shiftDate).hour(15).minutes(0).seconds(0)
    const dayShiftEnd = moment(shiftDate).hour(23).minutes(0).seconds(0)
    shifts.push(
      new Shift(shiftDate, 1, dayShiftStart, dayShiftEnd, 'Evening Shift')
    )

    const nightShiftStart = moment(shiftDate).hour(23).minutes(0).seconds(0)
    const nightShiftEnd = moment(shiftDate)
      .add(1, 'day')
      .hour(7)
      .minutes(0)
      .seconds(0)
    shifts.push(
      new Shift(shiftDate, 2, nightShiftStart, nightShiftEnd, 'Night Shift')
    )

    return shifts
  }

  getShiftByTimestamp(timestamp: Moment): Shift | null {
    const date = moment(timestamp).startOf('day')

    let shifts = this.generateShiftsForDate(date)
    for (let shift of shifts) {
      if (shift.checkShiftTime(timestamp)) {
        return shift
      }
    }

    const previousDate = moment(date).subtract(1, 'day')
    shifts = this.generateShiftsForDate(previousDate)
    const nightShift = shifts[2]
    if (nightShift.checkShiftTime(timestamp)) {
      return nightShift
    }

    return null
  }

  getPreviousShift(currentShift: Shift): Shift {
    if (currentShift.shiftSequence > 0) {
      const previousSequence = currentShift.shiftSequence - 1
      const shifts = this.generateShiftsForDate(currentShift.shiftDate)
      return shifts[previousSequence]
    } else {
      const previousDate = moment(currentShift.shiftDate).subtract(1, 'day')
      const shifts = this.generateShiftsForDate(previousDate)
      return shifts[2]
    }
  }

  getNextShift(currentShift: Shift): Shift {
    if (currentShift.shiftSequence < 2) {
      const nextSequence = currentShift.shiftSequence + 1
      const shifts = this.generateShiftsForDate(currentShift.shiftDate)
      return shifts[nextSequence]
    } else {
      const nextDate = moment(currentShift.shiftDate).add(1, 'day')
      const shifts = this.generateShiftsForDate(nextDate)
      return shifts[0]
    }
  }
}
