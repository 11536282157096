export interface NewReadSocketModel {
  deviceId: string
  errorCode?: forceReadErrorCode
  errorMessage?: string
  forceReadStatus: forceReadStatus
}

export enum forceReadStatus {
  Accepted = 'ACCEPTED',
  Disconnected = 'DISCONNECTED',
  BadSignal = 'BAD_SIGNAL',
  WatchCollect = 'WATCH_COLLECT',
  Monitor = 'MONITOR',
  Removed = 'REMOVED'
}

export enum forceReadErrorCode {
  Timeout = 'TIMEOUT',
  BiotDeviceNotFound = 'BIOT_DEVICE_NOT_FOUND',
  BioBeatDeviceNotFound = 'BIO_BEAT_DEVICE_NOT_FOUND',
  InvalidDevice = 'INVALID_DEVICE',
  Error = 'ERROR',
  Failed = 'FAILED'
}
