import { DepartmentDTO } from '../../shared/model/permission.model'

export function checkIsManual(
  isManual: boolean | undefined,
  department: DepartmentDTO | null
) {
  // if (!isManual || !department || (isManual && department.id === 'all'))
  // 	return false
  // else return !!department.isAutomatic
  return isManual
}
